import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../../utility/utils"

const InformationSecurityPolicyMobile = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  return (
    <Fragment>
      <div className="kuda-inner--heading kuda-disclaimer--section">
        <div className="kuda-section--inner general-style">
          <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
            <Link to="/mobile/ng/legal/" className="color-black mb-3 mt-4 back-page">
              <div className="align-self-center mr-2">
                <div className="f-14">
                  Legal
                </div>
              </div>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 mr-2 text-left">Nigeria</span>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 text-bold text-left">Information Security Policy</span>
            </Link>
            <div className="legal-left">
              <h1 className="kuda-section--heading text-lg-biz text-xlbold color-black title-bottom--spacing no-margins">
                Information Security Policy
              </h1>
            </div>
            {/* <p className="card-main--heading text-semi-bold color-black mb-0">
              The protection of your information at Kuda.
            </p> */}
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
              Introduction
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                This information security policy establishes an integrated set
                of measures that must be uniformly applied across Kuda to ensure
                a secured operating environment for its business operations.
                Customer Information, organisational information, supporting IT
                systems, processes and people that are generating, storing, and
                retrieving information are important assets of Kuda. The
                availability, integrity and confidentiality of information are
                essential in building and maintaining our competitive edge, cash
                flow, profitability, legal compliance, and respected company
                image.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
              Background
            </h1>

            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                Kuda Microfinance Bank is a financial service institution
                licensed by the Central Bank of Nigeria. Kuda was created to
                help Nigerians get the best out of their money without stress or
                exorbitant banking fees.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
              Scope
            </h1>

            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                This Information Security Policy applies to all Kuda staff,
                contractors, consultants and any third party with access to Kuda
                information assets.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
              Policy Statement
            </h1>

            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                This information security policy serves to provide Kuda with an
                approach to managing information risks and directives for the
                security of information assets and contracted services.
              </p>

              <p className="color-black bottom--spacing">
                This policy will also help provide a framework for creating our
                Information Security Management objectives.
              </p>

              <p className="color-black bottom--spacing">
                Kuda is committed to managing information risks and the
                protection of all organisational assets and will implement
                measures through an information security program to protect
                against breaches of confidentiality, failures of integrity or
                interruptions to the availability of its information assets.
              </p>

              <p className="color-black bottom--spacing">
                Kuda is committed to the continual improvement of its
                information security program and will comply with all applicable
                legal, regulatory, and contractual requirements related to
                information security in its services and operations.
              </p>

              <p className="color-black bottom--spacing">
                Users of Kuda information and information assets will comply
                with this policy and exercise a duty of care in relation to the
                operation and use of Kuda information and information systems.
              </p>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

export default InformationSecurityPolicyMobile
